@import "../../../../../../../theme/css-variables/master-variables.css";

.title-bar {
    min-height: 60px;
    padding: 4px 5px 0 15px;

    & .first-line {
        font-size: 11pt;
        font-weight: bold;
        height: 20px;
        line-height: 20px;
    }

    & .second-line {
        font-size: 10pt;
        font-style: italic;
        height: 18px;
        line-height: 18px;
    }

    & .third-line {
        font-size: 9pt;
        height: 18px;
        line-height: 18px;

        & .lock-icon {
            height: 10px;
            width: 10px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .title-bar {
        background-color: var(--base-palette-1-$(val));
        border-bottom: 1px solid var(--base-palette-3-$(val));
        color: var(--white-black-toggle-$(val));
    }
}
