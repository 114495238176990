@import "../../../../../../../theme/css-variables/master-variables.css";

.field-module .field-accordion-item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    justify-content: space-between;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    & .clip-overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    & .field-selected-column {
        width: 6px;
        padding-left: 12px;
        vertical-align: middle;
        padding-top: 7px;

        & .field-selected-mark {
            width: 6px;
            height: 30px;
        }
    }

    & .field-info {
        cursor: pointer;
        flex: 200 1 auto;
        padding: 4px 0 0 12px;
        max-width: 275px;

        & .field-name {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            line-height: 18px;
        }

        & .field-farm-acres {
            font-size: 12px;
            line-height: 20px;
            display: flex;
        }

        & .lock-icon {
            height: 10px;
            width: 12px;
            margin-left: 3px;
        }
    }

    & .rec-count,
    & .event-count {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: center;
        padding: 0 3px;
        width: 42px;

        & > div {
            text-align: center;

            &:first-child {
                line-height: 18px;
            }
        }

        & .units {
            font-family: open_sansitalic;
            font-size: 11px;
            line-height: 14px;
            text-align: center;
        }
    }

    & .field-context-menu-container {
        width: 23px;

        & .field-context-menu.menu-dots {
            margin-top: 3px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .field-accordion-item {
        background-color: var(--base-palette-4-$(val));
        color: var(--white-black-toggle-$(val));
        border-bottom: 1px solid var(--base-palette-1-$(val));

        & .context-menu .menu-dot {
            color: var(--base-palette-4-$(val)) !important;
        }

        & .field-selected-mark {
            background-color: var(--base-palette-3-$(val));
        }

        &:hover {
            background-color: var(--shadow-$(val));

            & .context-menu .menu-dot {
                color: var(--highlight-$(val)) !important;
            }
        }

        &.selected {
            background-color: var(--base-palette-5-$(val));

            & .field-selected-mark {
                background-color: var(--highlight-$(val));
            }

            & .context-menu .menu-dot {
                color: var(--base-palette-5-$(val)) !important;
            }
        }

        & .context-menu.customer-context-menu.menu-dots:hover,
        & .context-menu.customer-context-menu.menu-expanded {
            background-color: var(--base-palette-4-$(val));

            & .menu-dot {
                color: var(--highlight-$(val)) !important;
            }
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) .field-accordion-item {
        background-color: var(--base-palette-3-$(val));

        &.selected {
            color: var(--white-$(val));
        }
    }
}
