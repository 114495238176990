.portal-boundary-update-option-description {
    margin: 12px 20px;
}

.portal-boundary-update .dialog-container {
    width: 70%;
    height: 90%;
}

.portal-boundary-update-description {
    padding-bottom: 15px;
}

.portal-boundary-map-holder {
    margin-top: 0px;
    width: 100%;
    height: 80%;
}

.portal-boundary-map-div {
    width: 100%;
    height: 37vw;
}

.radio-button-container {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin: 0px;
}
.column {
    display: flex;
    align-items: left;
    vertical-align: middle;
}
.system-boundary-icon {
    width: 50px;
    height: 20px;
    margin-right: 0px;
    border: 5px solid #ffffff;
    background-color: #896b1e;
}

.jd-boundary-icon {
    width: 50px;
    height: 20px;
    margin-right: 0px;
    border: 5px solid #cd2da5;
    background-color: transparent;
}
.radio-button-container {
    align-items: center;
    margin: 10px;
}

.side-by-side-radio {
    align-items: center;
    margin: 10px;
    display: flex;
    width: 80%;
}

.radio-label {
    padding-top: 5px;
}

.radio-div {
    align-items: left;
    margin: 0px;
    display: flex;
}

.radio-div .radio-container.radio-button-container {
    margin: 5px;
}
