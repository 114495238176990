.myagdata-registration-dialog {
    width: 28%;

    .dialog-box-children {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        & > div {
            margin: 5px;
        }

        & .connected-selection {
            grid-column: span 2;

            & .select-form-input {
                margin: 0;
            }
        }

        & .resend-email {
            margin: auto 5px;
        }
    }
}
